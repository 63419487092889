.hexagonBottom {
  top: 75rem;
  float: right;
  right: 1rem;
  position: absolute;
  margin: 0;
  background: linear-gradient(to left, #801313, #9d1414);

  border-radius: 10px;
  width: 100px;
  height: 63px;
  box-sizing: border-box;
  transition: all 1s;
  border: 0.4vh solid transparent;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.6);
}

.hexagonBottom:before,
.hexagonBottom:after {
  content: "";
  border: inherit;
  position: absolute;
  top: -0.5vh;
  left: -0.5vh;
  background: linear-gradient(to left, #801313, #9d1414);
  border-radius: inherit;
  height: 100%;
  width: 100%;
}

/* Align them in such a way
that they form a hexagon */
.hexagonBottom:before {
  transform: rotate(60deg);
}
.hexagonBottom:after {
  transform: rotate(-60deg);
}

.hexagonTop {
  top: 40rem;
  float: left;
  left: 1rem;
  position: absolute;
  margin: 0;
  background: linear-gradient(to left, #111111, #2d2d2d);
  border-radius: 10px;
  width: 100px;
  height: 63px;
  box-sizing: border-box;
  transition: all 1s;
  border: 0.4vh solid transparent;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.4);
}

.hexagonTop:before,
.hexagonTop:after {
  content: "";
  border: inherit;
  position: absolute;
  top: -0.5vh;
  left: -0.5vh;
  background: linear-gradient(to left, #111111, #2d2d2d);
  border-radius: inherit;
  height: 100%;
  width: 100%;
}

/* Align them in such a way
  that they form a hexagon */
.hexagonTop:before {
  transform: rotate(60deg);
}
.hexagonTop:after {
  transform: rotate(-60deg);
}

/* hides hexagon content for mobile display */
@media (max-width: 811px) {
  .hexagonBottom {
    display: none;
  }
  .hexagonTop {
    display: none;
  }
}

.hexagonBottom {
  box-shadow: rgba(0, 0, 0, 0.066);
}
